import { Prisma } from "@prisma/client";
import { useRouter } from "next/router";

type Props = {
  price: Prisma.Decimal;
  reduced?: boolean;
};

export const Price = ({ price, reduced }: Props) => {
  const router = useRouter();
  const priceNumber = reduced ? Number(price) * 0.6 : Number(price);

  return (
    <span className="text-sm font-medium text-slate-600 md:text-lg">
      <>
        {new Intl.NumberFormat(router.locale, {
          style: "currency",
          currency: "EUR",
        }).format(priceNumber)}{" "}
      </>
    </span>
  );
};

export default Price;
