import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Paytone_One, Outfit } from "next/font/google";
import useTranslation from "next-translate/useTranslation";

import Button from "components/CustomButton";
import clsx from "clsx";

const PaytoneOne = Paytone_One({
  subsets: ["latin"],
  weight: ["400"],
  variable: "--font-paytone-one",
});
const OutfitFont = Outfit({
  subsets: ["latin"],
  variable: "--font-outfit",
  weight: "variable",
});

type Props = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  children?: React.ReactNode;
  title: string;
  click: () => void;
  danger?: boolean;
};

export function DialogV2({
  isOpen,
  setIsOpen,
  children,
  title,
  click,
  danger = false,
}: Props) {
  const { t } = useTranslation("post");

  function closeModal() {
    setIsOpen(false);
  }

  function closeModalAndExecute() {
    click();
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          style={OutfitFont.style}
          className="relative z-10"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={clsx(
                    "w-full max-w-md transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all",
                    danger ? "bg-red-100" : "bg-slate-200"
                  )}
                >
                  <Dialog.Title
                    as="h3"
                    style={PaytoneOne.style}
                    className="font-sans text-lg leading-6 text-indigo-600  dark:text-fuchsia-600"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2 " style={OutfitFont.style}>
                    <div className="text-sm text-gray-500">{children}</div>
                  </div>

                  <div className="mt-4">
                    <Button
                      click={closeModalAndExecute}
                      additionalClassNames="mr-2"
                    >
                      {t("okayButton")}
                    </Button>
                    <Button click={closeModal}>{t("cancelButton")}</Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
