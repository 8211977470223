export const getBookedIdForUser = (
  id: number | undefined,
  bookEntry: { userId: number; id: number }[] | undefined
) => {
  if (!bookEntry) return 0;
  const out = bookEntry.filter(function (item) {
    return item.userId === id;
  });
  return out[0]?.id;
};

export default getBookedIdForUser;
