import { getUsers } from "./prismaQueries/getUsers";

type Users = Awaited<ReturnType<typeof getUsers>>;

export const mapUserIdToName = (
  userId: number,
  users: Users | undefined
): string => {
  if (!users) return "no name";
  const user = users.find((user) => {
    return user.id === userId;
  });
  return user?.name || "loading...";
};

export default mapUserIdToName;
