import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";

type Props = {
  aDate: Date;
  shownText: string;
  locale?: string;
  additionalWrapperClasses?: string;
};

export const BookableUntil = ({
  locale,
  aDate,
  shownText,
  additionalWrapperClasses,
}: Props) => {
  const { t } = useTranslation("post");

  return (
    <div className={clsx(additionalWrapperClasses)}>
      <span className="text-xs leading-6 text-slate-500 dark:text-slate-400">
        {t(shownText)}:{" "}
        {new Intl.DateTimeFormat(locale, {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(aDate))}
      </span>
    </div>
  );
};

export default BookableUntil;
