import { useQuery } from "@tanstack/react-query";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { fetcher } from "lib/fetcher";
import { getUsers } from "lib/prismaQueries/getUsers";
import { useSession } from "next-auth/react";
import { getEntries } from "lib/prismaQueries/getEntries";
import { sortBookedUsersWithNameLookup } from "lib/sortBookedUsers";

type Props = {
  bookEntry: Awaited<ReturnType<typeof getEntries>>[number]["BookEntry"];
};

const container = {
  hidden: { opacity: 1, height: 0 },
  visible: {
    opacity: 1,
    height: "auto",
    transition: {
      delayChildren: 0.005,
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { x: -20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

export const UserTableNew = ({ bookEntry }: Props) => {
  const { data: users, isLoading: isLoadingUsers } = useQuery(["users"], () =>
    fetcher<Awaited<ReturnType<typeof getUsers>>>(`/api/users`)
  );
  const { data: session } = useSession();

  const { t } = useTranslation("common");

  if (isLoadingUsers) {
    return <div className="mt-3 text-sm text-slate-500">{t("loading")}</div>;
  }

  if (bookEntry.length === 0) {
    return null;
  }
  const liClassName =
    "text-xs md:text-md max-w-[140px] text-center relative grow rounded-sm p-2 text-slate-600 dark:text-slate-300 border-[1px] border-slate-300 dark:border-slate-700";
  const currentUserId = session?.user?.id;

  const sortedBookedUsers = sortBookedUsersWithNameLookup({
    users: users,
    bookEntry: bookEntry,
  });

  return (
    <motion.ul
      className="flex flex-wrap text-sm"
      variants={container}
      initial="hidden"
      animate="visible"
      exit={{ height: 0 }}
    >
      {sortedBookedUsers.map((entry, index: number) => {
        const name = entry?.name;
        if (name) {
          return (
            <motion.li
              variants={item}
              key={`${entry.id}-${index}`}
              className={clsx(
                `${liClassName}`,
                currentUserId === entry.userId &&
                  "[text-shadow:_0_1px_10px_rgb(52_211_153_/_100%)]",
                sortedBookedUsers.length !== index + 1 && "grow"
              )}
            >
              {name}
            </motion.li>
          );
        }
        return null;
      })}
    </motion.ul>
  );
};

export default UserTableNew;
