import mapUserIdToName from "./mapUserIdToName";
import { getBookings } from "./prismaQueries/getBookings";
import { getEntries } from "./prismaQueries/getEntries";
import { getUsers } from "./prismaQueries/getUsers";
import { getEntriesForAdmin } from "./prismaQueries/getEntriesForAdmin";

type Props = {
  bookEntry: Awaited<ReturnType<typeof getBookings>>;
};

type SortUsersProp = Awaited<ReturnType<typeof getUsers>> | undefined;

type LookupProps = {
  bookEntry:
    | Awaited<ReturnType<typeof getEntries>>[number]["BookEntry"]
    | undefined;
  users: Awaited<ReturnType<typeof getUsers>> | undefined;
};

type LookupPropsAdmin = {
  bookEntry:
    | Awaited<ReturnType<typeof getEntriesForAdmin>>[number]["BookEntry"]
    | undefined;
  users: Awaited<ReturnType<typeof getUsers>> | undefined;
};

type ReturnLookup = Awaited<
  ReturnType<typeof getEntries>
>[number]["BookEntry"][number] & { name: string };

type ReturnLookupAdmin = Awaited<
  ReturnType<typeof getEntriesForAdmin>
>[number]["BookEntry"][number] & { name: string };

export const sortBookedUsers = ({ bookEntry }: Props) => {
  return bookEntry.sort((a, b) => {
    if (a.user.name === null) return 1;
    if (b.user.name === null) return 0;
    return a.user.name.localeCompare(b.user.name);
  });
};

export const sortUsers = (users: SortUsersProp) => {
  if (!users) return [];
  return users.sort((a, b) => {
    if (a.name === null) return 1;
    if (b.name === null) return 0;
    return a.name.localeCompare(b.name);
  });
};

export const sortBookedUsersWithNameLookup = ({
  users,
  bookEntry,
}: LookupProps): ReturnLookup[] =>
  users && bookEntry
    ? bookEntry
        .map((entry) => {
          return {
            ...entry,
            name: mapUserIdToName(Number(entry.userId), users),
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    : [];

export const sortBookedUsersWithNameLookupAdmin = ({
  users,
  bookEntry,
}: LookupPropsAdmin): ReturnLookupAdmin[] =>
  users && bookEntry
    ? bookEntry
        .map((entry) => {
          return {
            ...entry,
            name: mapUserIdToName(Number(entry.userId), users),
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    : [];
