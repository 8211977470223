import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";

type Props = {
  times: number;
  additionalWrapperClasses?: string;
};

export const BookedTimes = ({ times, additionalWrapperClasses }: Props) => {
  const { t } = useTranslation("post");

  return (
    <div className={clsx(additionalWrapperClasses)}>
      <span className="text-xs leading-6 text-indigo-600 transition-all dark:text-fuchsia-600">
        {times} {times === 1 ? t("time") : t("times")} {t("booked")}
      </span>
    </div>
  );
};

export default BookedTimes;
