export const isUserBookedOnEntry = (
  id: number | undefined,
  bookEntry: { userId: number }[] | undefined
) => {
  if (!bookEntry) return { bookedCount: 0, isBooked: false };
  const out = bookEntry.filter(function (item) {
    return item.userId === id;
  });
  return { bookedCount: out.length, isBooked: out.length > 0 };
};

export default isUserBookedOnEntry;
