import { EntryType } from "@prisma/client";
type Props = {
  count?: number;
  capping?: number;
  etype: EntryType;
};

export const AllBookingsCountConnected = ({ count, capping, etype }: Props) => {
  return (
    <div className="bg-gradient-to-r from-indigo-600 to-fuchsia-600 bg-clip-text px-1 py-1 text-4xl text-slate-400 text-transparent  md:text-2xl ">
      <span className="inline-block min-w-[22px] text-center">{count}</span>
      {etype === EntryType.CAPPED ? (
        <span className="ml-1 inline-block min-w-[22px] text-center text-xl">
          {" "}
          /{capping}
        </span>
      ) : null}
    </div>
  );
};

export default AllBookingsCountConnected;
